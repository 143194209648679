.clear {
  clear: both;
}

p.float-right {
  margin-left: 15px;

  @include breakpoint(small down) {
    margin-left: 0;
    float: none !important;
  }
}

.content-output {
	position: relative;
}

.welcome {
	background-color: get-color('secondary');
	color: $white;
	padding: 1.8rem;
	top: -140px;
	@include horizontal-center;

	h1 {
		color: $white;
	}
}


.content-accordion {
	.accordion-item.is-active {
		color: $white;

		.accordion-title {
			background-color: $accordion-item-background-hover;
			color: $white;
		}
	}

	.accordion-title {
		text-transform: uppercase;
		border-top: 5px solid #fff;
		border-left: 0 none;
		border-right: 0 none;

		&:focus,
		&:hover {
			color: $white;
			background-color: $accordion-item-background-hover;
		}

		@include breakpoint(small down) {
			font-size: 1.1rem;
		}
	}

	.accordion-title::before {
		position: absolute;
		top: 50%;
		right: 1rem;
		margin-top: 0;
		content: '+';
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}

.label.primary {
	background-color: $light-gray;
	border-left: 4px solid #fdd837;
	margin-top: 15px;
	margin-right: 10px;
}