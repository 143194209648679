.footer {
	background-color: #7d7d7d;
	color: $white;

	a {
		color: $white;
	}

	.dropdown.menu {
		a {
			text-transform: uppercase;
			font-style: italic;
			color: $white;
		}
	}

	.kontakt-box {
		padding-top: 1.5rem;
	}

	.footer-menu {
		background-color: get-color('primary');
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-left: -.9375rem;

		@include breakpoint(medium down) {
			text-align: center;
			margin-left: 0;
		}
	}

	@include breakpoint(medium down) {
		text-align: center;

		.float-right {
			float: none !important;
			text-align: center !important;
			display: inline;
		}

		.dropdown.menu li {
			display: inline-block;
		}
	}
}