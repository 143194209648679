
.bfFormDiv {
	padding-bottom: 50px;
}

.breezingforms_iframe {
	width: 100%;
	height: 600px;
}

.bfQuickMode {
	font-family: $body-font-family !important;
	color: $black !important;
	width: 100%;
	margin: 20px auto 0;

	label {
		color: $black;
	}

	.bfElemWrap {
		margin-bottom: 20px;
	}

	.pull-right {
		float: none !important;
	}

	select {
		margin: 0;
	}

	.icon-asterisk {
		font-style: normal;

		&:before {
			font-family: "Font Awesome 5 Free";
			content: "";
			display: inline-block;
			position: relative;
			top: 2px;
		}
	}

	input[type=checkbox],
	input[type=radio] {
		box-sizing: border-box;
		padding: 0;
		position: absolute;
		margin-top: .3rem;
		margin-left: 0;
		top: 3px;
	}

	#bfLabel16 {
		width: 100%;
		vertical-align: text-top;
		padding-left: 1rem;
		color: $black !important;
		font-weight: 400;
	}

	.controls.form-inline {
		position: relative;
	}
}

.bfQuickMode .controls {
	label,
	select,
	button,
	textarea,
	input[type="text"],
	.control-label {
		font-family: $body-font-family !important;
		font-size: rem-calc(16);
		color: $black;
		width: 100%;
		max-width: 100%;
	}
}

.bfQuickMode input[type="text"],
.bfQuickMode textarea {

	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $black;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		color: $black;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		color: $black;
	}
	&:-moz-placeholder { /* Firefox 18- */
		color: $black;
	}
}

.bfQuickMode .form-actions .bfSubmitButton,
.bfQuickMode .bfCustomSubmitButton {
	background-color: $primary-color !important;
	font-family: $body-font-family !important;
	color: $white !important;
	text-transform: uppercase;
	display: block;
	margin: 0 auto;
	max-width: 200px !important;
	float: right;
}

.bfQuickMode input[type="text"], .bfQuickMode textarea, .bfQuickMode .uneditable-input, .bfQuickMode input[type="text"]:focus, .bfQuickMode textarea:focus, .bfQuickMode .uneditable-input:focus, .bfQuickMode input[type="text"]:hover, .bfQuickMode textarea:hover, .bfQuickMode .uneditable-input:hover {
	font-family: $body-font-family !important;
	//border-top: 0 none;
	//border-left: 0 none;
	//border-right: 0 none;
	//border-bottom: 0 none;
	//box-shadow: none;
}

.bfQuickMode textarea.ff_elem {
	resize: vertical;
	height: 120px;
}

.intro-kontakt {
	padding-bottom: 35px;
	padding-left: 0;
}

#col1 {
	padding-left: 0;
}
#col2 {
	padding-right: 0;
}