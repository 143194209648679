.header {
	.dropdown {
		margin-top: 120px;
		margin-bottom: 20px;

		li {
			a {
				text-transform: uppercase;
				background-color: transparent;
				color: $black;
				border-bottom: 2px solid transparent;

				&:hover {
					border-bottom: 2px solid get-color('primary');
				}
			}

			&.active>a {
				background: transparent;
				color: $black;
				border-bottom: 2px solid get-color('primary');
			}
		}
	}

	.logo {
		@include breakpoint(medium down) {
			margin-bottom: 10px;
		}
	}
}

.mod-languages {
	margin-top: 69px;
}

// Off-Canvas

.menu-icon {
	width: 30px;
	position: relative;
	top: 45px;

	@include breakpoint(small only) {
		top: 25px;
	}
}


.off-canvas {
	padding-top: 3.5rem;

	li {
		display: block;
		width: 100%;
	}
}